import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/auth/launcher",
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/auth/MainView.vue'),
    children: [
      {
        path: 'launcher',
        name: 'launcher',
        component: () => import('@/views/auth/LauncherView.vue')
      },
      {
        path: 'termsOfPrivacy',
        name: 'termsOfPrivacy',
        component: () => import('@/views/auth/TermsOfPrivacy.vue')
      },
      {
        path: 'signIn',
        name: 'signIn',
        component: () => import('@/views/auth/SignInView.vue')
      },
      {
        path: 'signUp',
        name: 'signUp',
        component: () => import('@/views/auth/SignUpView.vue')
      },
      {
        path: 'forgetPassword',
        name: 'forgetPassword',
        component: () => import('@/views/auth/ForgetPasswordView.vue')
      },
    ]
  },
  {
    path: '/membership',
    name: 'membership',
    component: () => import('@/views/main/MembershipView.vue'),
  },
  {
    path: '/membership/payment',
    name: 'membershipPayment',
    component: () => import('@/views/subscribe/PaymentView.vue')
  },
  {
    path: '/membership/depositInfo',
    name: 'depositInfo',
    component: () => import('@/views/subscribe/DepositInfoView.vue')
  },
  {
    path: '/membership/settings',
    name: 'membershipSettings',
    component: () => import('@/views/main/MembershipSettingsView.vue')
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import('@/views/survey/SurveyView.vue')
  },
  {
    path: '/survey_result',
    name: 'survey_result',
    component: () => import('@/views/survey/SurveyResultView.vue')
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@/views/subscribe/MainView.vue'),
    children: [
      {
        path: 'payment',
        name: 'payment',
        component: () => import('@/views/subscribe/PaymentView.vue')
      },
      {
        path: 'complete',
        name: 'complete',
        component: () => import('@/views/subscribe/CompleteView.vue')
      },
    ]
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/MainView.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/main/HomeView.vue')
      },
      {
        path: 'challenge',
        name: 'challenge',
        component: () => import('@/views/main/ChallengeView.vue')
      },
      {
        path: 'store',
        name: 'store',
        component: () => import('@/views/main/StoreView.vue')
      },
      {
        path: 'store_item',
        name: 'store_item',
        component: () => import('@/views/main/StoreItemView.vue')
      },
      {
        path: 'store_pay',
        name: 'store_pay',
        component: () => import('@/views/main/StorePayView.vue')
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/views/main/MyInfoView.vue'),
      },
      {
        path: 'my_subscribe',
        name: 'my_subscribe',
        component: () => import('@/views/main/myinfo/SubscribeView.vue'),
      },
      {
        path: 'my_survey',
        name: 'my_survey',
        component: () => import('@/views/main/myinfo/SurveyView.vue'),
      },
      {
        path: 'my_challenge',
        name: 'my_challenge',
        component: () => import('@/views/main/myinfo/ChallengeView.vue'),
      },
      {
        path: 'my_delivery',
        name: 'my_delivery',
        component: () => import('@/views/main/myinfo/DeliveryView.vue'),
      },
      {
        path: 'my_alarm',
        name: 'my_alarm',
        component: () => import('@/views/main/myinfo/AlarmView.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
