import App from "./App.vue";

import Vue from "vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import axios from "axios";

/* https://www.codetd.com/en/article/6809485 */
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  vuetify,
  router,

  /* 
  Real
    render: function (createElement) {
      return createElement(App);
    }

  Short
    render: function (h) {
      return h(App);
    }

  More short
    render: h => h (App) */

  render: (h) => h(App),
}).$mount("#app");
