<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
